import React from "react"
import { Layout, SEO } from "../components"
import {
  Box,
  Grid,
  Typography,
  Card,
  CardContent,
  CardActionArea,
  CardMedia,
  CardActions,
  Button,
} from "@material-ui/core"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import { useStaticQuery, graphql, Link, navigate } from "gatsby"

const useStyles = makeStyles({
  root: {
    display: "flex",
    minWidth: 275,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  styledInfo: {
    color: "rgb(255, 193, 7)",
    fontSize: 25,
    fontWeight: "bolder",
  },
  simpleInfo: {
    marginLeft: "10px",
    marginTop: "4px",
  },
  styledDiv: {
    backgroundColor: "rgba(25, 25, 35, 0.92)",
    height: "80px",
    width: "93%",
    margin: "auto",
  },

  media: {
    height: 220,
  },
  card: {
    margin: 6,
    minHeight: "230px",
    background:
      "linear-gradient( 159deg,rgba(37,37,50,0.98) 0%,rgba(35,35,45,0.98) 100% )",
    color: "white",
    boxShadow: "0 3px 8px 0 rgba(15, 15, 20, 0.9)",
    padding: "5px",
  },
  hr: {
    backgroundColor: "white",
  },
  question: {
    fontWeight: "bolder",
  },
  answer: {
    marginLeft: "10px",
    fontWeight: "200",
  },
})

function shuffle(array) {
  var currentIndex = array.length,
    randomIndex

  while (0 !== currentIndex) {
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex--
    ;[array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ]
  }

  return array
}

const contact = () => {
  const classes = useStyles()
  const { projects } = useStaticQuery(
    graphql`
      query MyAllProjects {
        projects: allContentfulProjects {
          edges {
            node {
              id
              description {
                description
              }
              name
              uri
              gitHubUri
              category
              images {
                id
                title
                file {
                  url
                }
              }
            }
          }
        }
      }
    `
  )
  return (
    <Layout title={"My Portfolio"}>
      <SEO title="My Portfolio" previewImage={{ width: 267 }} />
      <Box mt={2} ml={3} mr={3}>
        <Box mt={4}>
          <Typography variant="h5" component="h4">
            My Portfolio
          </Typography>
          <Box mt={2} />
          <hr className={classes.hr} />
          <Box>
            <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="center"
            >
              {projects ? (
                projects.edges.map(
                  ({
                    node: {
                      id,
                      name,
                      description,
                      uri,
                      gitHubUri,
                      images,
                      category,
                    },
                  }) => {
                    return (
                      <Grid key={id} item xs={12} sm={12} md={4} lg={4} xl={4}>
                        <CardActionArea>
                          <Link
                            to={`/project/${id}`}
                            style={{
                              textDecoration: "none",
                            }}
                          >
                            <Card className={`${classes.card} special-card`}>
                              {images && (
                                <CardMedia
                                  className={classes.media}
                                  image={`https://${images[0].file.url}`}
                                  title={images[0].title}
                                />
                              )}
                              <CardContent>
                                <Typography
                                  className={classes.title}
                                  gutterBottom
                                >
                                  {category}
                                </Typography>
                                <Typography
                                  gutterBottom
                                  variant="h5"
                                  component="h2"
                                >
                                  {name}
                                </Typography>
                                <Typography variant="body2" component="p">
                                  <p
                                    style={{ paddingTop: 10, paddingBottom: 7 }}
                                    dangerouslySetInnerHTML={{
                                      __html: description.description,
                                    }}
                                  ></p>
                                </Typography>
                              </CardContent>
                              {/* <CardActions>
                                <Button
                                  size="large"
                                  style={{
                                    backgroundColor: "rgb(255, 193, 7)",
                                  }}
                                >
                                  <a
                                    style={{
                                      textDecoration: "none",
                                      color: "black",
                                    }}
                                    target="_blank"
                                    href={gitHubUri}
                                  >
                                    Github
                                  </a>
                                </Button>
                                {uri && (
                                  <Button
                                    size="large"
                                    style={{
                                      backgroundColor: "rgb(255, 193, 7)",
                                    }}
                                  >
                                    <a
                                      style={{
                                        textDecoration: "none",
                                        color: "black",
                                      }}
                                      href={uri}
                                    >
                                      Show Demo
                                    </a>
                                  </Button>
                                )}
                              </CardActions> */}
                            </Card>
                          </Link>
                        </CardActionArea>
                      </Grid>
                    )
                  }
                )
              ) : (
                <p>No services</p>
              )}
            </Grid>
          </Box>
        </Box>
      </Box>
    </Layout>
  )
}

export default contact
